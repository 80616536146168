.dashboard {
  margin: 50px auto;
}

.part {
  width: 100%;
  height: 200px;
  margin: 30px auto !important;
}

.partButton {
  border: 2px solid #2163e8;
  background-color: transparent;
  padding: 60px 50px;
  text-align: center;
  text-decoration: none;
  width: 300px ;
  border-radius: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.partButton:hover {
  background-color: #2163e8;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
