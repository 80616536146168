.brief {
    display: flex;
    flex-wrap: wrap;
    flex:3; 
    gap:15px;
    margin: 40px 10px;
    justify-content: center;
}

.Dpobrief {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: justify;

}

.Dpobrief .ownersImgs {
    display: flex;
    gap: 30px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
@media (max-width: 1000px) {
    .brief{
        flex-direction: column;
    }
}

.brief .titleAndDesc  {
    flex: 3;
    margin: 20px 10px;
}

.brief .whoImg {
    flex: 2;
    margin: 20px 10px;
}

.whoImg img {
    object-fit: cover;
    width:350px;
    min-width: 300px;
    max-width: 100%;
    height: 400px;
    border-radius: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.whoImg h4 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5;
    color:#2163e8;
    text-align: center;
}

.titleAndDesc h1, .titleAndDesc h3 , .latestSection h2 {
    font-size: 3rem !important;
    margin-bottom: 20px;
    border-bottom: 4px solid #2163e8;
    padding: 15px;
    border-radius: 25px;
    width: fit-content;
}

.titleAndDesc p {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.5;
    color:#a1a1a8;
    margin-bottom: 30px;
    justify-content: center ;
}

.team h3{
    text-align: center;
    border-bottom: 4px solid #2163e8;
    padding: 15px;
    border-radius: 25px;
    width: fit-content;
}

.team h3,.titleAndDesc h3{
    font-size: 2.5rem !important;
    margin-bottom: 20px;
    border-bottom: 4px solid #2163e8;
    padding: 15px;
    border-radius: 25px;
    width: fit-content;
    /* color: #2163e8; */
}

.titleAndDesc h4{
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5;
    color:#2163e8;
    justify-content: center ;
}

@media (max-width:1000px) {
    .titleAndDesc h3{
        font-size: 1.6rem !important;
    }
}

.team .members {
    display: flex !important;
    gap:10px !important;
    flex-wrap: wrap !important;
    justify-content: center ;
    align-items: center;
}

.latestSection {
    margin: 30px;
}
