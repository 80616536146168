.createButtonContainer {
  margin: 30px 10px;
}

.createButton {
  border: 2px solid #2163e8;
  background-color: transparent;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  width: 200px;
  border-radius: 20px;
  font-size: 1rem !important;
  font-weight: 700 !important;
  display: block;
  /* margin-left: auto;
  margin-right: auto; */
}

.createButton:hover {
  background-color: #2163e8;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.loading h3 {
  color: #2163e8;
}