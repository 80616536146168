.login {
    width: 50%;
    padding: 30px 50px;
    margin: 50px auto;
    background-color: rgba(33, 99, 232, 0.2);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media (max-width:1200px) {
    .login {
        width: 90%;
    }
}
.loginTitle h2 {
    text-align: center;
    margin: 20px auto 40px;
}

.inputs {
    text-align: center;
    margin: 20px auto 30px;
}

.submit {
    margin: 30px auto;
    text-align: center;
}

.submit button {
    width: 70%;
    background-color: #2163e8;
    text-align: center;
    color: white;
    padding: 10px;
    border-radius: 0.25rem;
    border: none;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    font-weight: 600;
    font-size: 1.3rem;
}

@media (max-width: 900px) {
    .submit button {
      width: 85%;
    }
  }
.submit button:hover {
    background-color: #072d79;
}