.talkCard {
    padding: 50px  !important;
    min-height: 300px;
    border-radius: 20px;
    max-width: 90% !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.imgAndTalk {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content:flex-start;
    align-items:flex-start;
    gap: 30px;
}


@media (max-width:500px) {
    .imgAndTalk {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.talkImg img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}


.talkWords p {
    font-size: 1.5rem;
    font-weight: 600;
}

.talkName h4 {
    margin-right: 20px;
}