.tableImg {
    width:100px;
    height: 100px;
}

table {
    text-align:right !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


td{
    text-align:right !important;
    font-weight: 600;
}

thead tr h6 {
    color:#2163e8;
    padding: 20px;
    font-weight: 800 !important;
    font-size: 20px !important;
}
.icons {
    padding: 20px auto;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.icons button {
    background: #2163e8;
    padding: 10px 20px;
    color: white;
    outline: none;
    border: none;
}

.delIcon, .updIcon {
    cursor: pointer;
}

.delIcon:hover, .updIcon:hover {
    color: #2163e8;
}