.latestPosts {
    background-color: transparent !important;
    margin: 20px;
    text-align: center;
}

.postImage img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
}

.postTitle h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    cursor: pointer;
}

.postTitle p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #a1a1a8;
}


.latestNewsTitle {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 15px 0;
    height: 60px;
    overflow: auto;
}

.latestNewsTitle h4{
    font-size: 2rem !important;
}

.latestPostImg {
    height: 200px;
    width: 100%;
}

.latestPostsTitle {
    height: 70px !important;
    overflow: hidden !important;
    text-align: right !important;
}

.latestPostsTitle:hover {
    text-decoration: underline solid black 2px;
    cursor: pointer;
}

.cardBody {
    background-color: rgba(33, 99, 232, 0.2);
}