.newsTitle {
    margin: 40px auto;
}

.newsTitle h1 {
    font-size: 3rem !important;
    font-weight: 600; 
    color: #2163e8;
    text-align: center;
}

.allPosts {
    display: flex;
    flex-wrap: wrap;
    /* gap: 15px; */
    align-items: center;
    margin: 40px auto;
}

.latestPostsTitle h2 {
    font-size: 3rem !important;
    margin-bottom: 20px;
    color: #2163e8;
}

li.page-item a, li.page-item span {
    background-color: rgba(33, 99, 232, 0.2) !important; 
    font-size: 1.3rem;
}