.navbarr {
    padding: 10px 0;
    margin-bottom: 70px !important;
}


.nav-bar {
    background-color: transparent;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

 
#navbarScrollingDropdownDark {
    color: white !important;
}

#navbarScrollingDropdownDark:hover {
    color: #2163e8 !important;
}
/* Common link styles */
.link, .dropdown-item {
    font-size: 1rem !important;
    font-weight: bold !important;
    text-decoration: none !important;
    color: black !important;
}

.link:hover, .dropdown-item:hover, .linkDark:hover {
    color: #2163e8 !important;
}


.link .dropdown-item:hover {
    color: #2163e8 !important;
}

/* Dark mode link styles */
.linkDark, .linkDark .dropdown-item {
    font-size: 1rem !important;
    font-weight: bold !important;
    color: #f5f5f5 !important;
    text-decoration: none !important;
}

/* Hover effects for dark mode */
.linkDark .dropdown-item:hover {
    color: #2163e8 !important;
}

/* Inner links */
.innerLink {
    text-decoration: none;
    color: rgb(0 0 0 / 65%);
}
#navbarScrollingDropdown:hover {
    color: #2163e8 !important;
}
.innerLinkDark {
    text-decoration: none;
    color: white;
}

.innerLink:hover, .innerLinkDark:hover {
    color: #2163e8 !important;
}

/* Additional styles for dropdowns */
.navbarr .nav-bar .dropdown-menu {
    background-color: rgba(33, 99, 232, 0.815) !important;
    border: none;
}

.navbarr .nav-bar .dropdown-item {
    background-color: transparent !important;
    color: black !important;
}


.linkDark .dropdown-item {
    color: #f5f5f5 !important;
}

.linkDark .dropdown-item:hover {
    color: #2163e8 !important;
}

/* Dark mode dropdown title styles */
.linkDark .dropdown-toggle {
    color: #f5f5f5 !important; /* Light color for dropdown title */
    font-weight: bold !important; /* Bold text for the title */
}

/* Dark mode dropdown item styles */
.linkDark .dropdown-item {
    /* color: #f5f5f5 !important; Light color for dropdown items */
    background-color: transparent !important; /* Ensure background is transparent */
}

/* Hover effect for dropdown items in dark mode */
.linkDark .dropdown-item:hover {
    color: #2163e8 !important; /* Change color on hover */
    background-color: rgba(33, 99, 232, 0.5) !important; /* Subtle background on hover */
}

/* Ensure the dropdown menu background adjusts in dark mode */
.navbarr .nav-bar .dropdown-menu {
    background-color: rgba(33, 99, 232, 0.815) !important; /* Maintain background color */
    border: none;
}

.dropdown-menu[data-bs-popper] {
    background-color: #2163e8 !important;
    color:white !important;
}

.dropdown-menu[data-bs-popper] a:hover{
    background-color: #fff !important;
}