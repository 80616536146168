.createPost h1 {
    border-bottom: 4px solid #2163e8;
    padding: 15px;
    border-radius: 25px;
    margin: 20px auto;
    width: fit-content;
    font-size: 2rem !important;
  }
  
  .formPost {
    margin: 50px auto;
  }
  
  .input-label {
    margin: 30px 0;
  }
  
  @media (max-width: 800px) {
    .input-label {
      margin: 30px auto;
      text-align: center;
    }
  }
  
  .input-label label {
    margin: 0 10px;
    font-size: 1.2rem !important;
    font-weight: 500;
  }
  
  @media (max-width: 900px) {
  }
  
  textarea {
    width: 70%;
    height: 200px;
    border: 3px solid #2163e8;
    outline: none;
    margin: 20px 10px;
    padding: 15px;
    border-radius: 0.25rem;
    /* color: white; */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  
  textarea:focus {
    border: 2px solid #6ea8fe;
  }
  .createBtn {
    text-align: center;
    margin: 30px auto;
  }
  
  .createBtn button {
    color: white;
    background-color: #2163e8;
    padding: 10px 50px;
    border-radius: 0.25rem;
    outline: none;
    border: none;
  }
  
  .createBtn button:hover {
    background-color: #072d79;
  }
  .selectt {
    width: 70%;
    height: 50px;
    border: 1px solid #03346E;
    outline: none;
    margin: 20px 10px !important;
    padding: 15px;
    border-radius: 0.25rem;
    /* color: white; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }