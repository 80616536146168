.specNew {
    padding: 50px 20px;
}

.specNewsTitle h1 {
    font-size: 2.3rem !important;
    font-weight: bold;
    margin:30px 10px;
}

.summary {
    margin: 30px 10px;
}

.description {
    margin: 20px 30px;
    text-align: right;
    
}

.summary, .description {
    color:#72727c;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: justify;
}

.postImagee{
    text-align: center;
    margin: 40px 30px;
}

.postImagee img {
    width: 70%;
    height: 400px;
    object-fit: fill;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media (max-width:900px) {
    .postImagee img {
        width: 90%;
        height: 300px;
    }
}


.latestPosts {
    margin:30px 10px;
}

.latestPosts h3 {
    font-size: 2rem !important;
}

.copy {
    /* float: left; */
    margin: 30 auto;
    align-items: center;
    text-align: center;
}
.copy button {
    background: #2163e8;
    padding: 10px 20px;
    color: white;
    outline: none;
    border: none;
    margin: 30px auto;
    display: block;
}
