*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box !important;
    direction: rtl;
    font-family: "Almarai", sans-serif;
}

.app {
    width: 100%;
    max-width: 100% !important;
}

/* Total scrollbar width */
::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2163e8; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2163e8d0; 
  }
  
