.input[type="text"], .input[type="email"], .input[type="password"] {
  width: 70%;
  height: 50px;
  border: 1px solid #03346E;
  outline: none;
  margin: 20px 10px;
  padding: 15px;
  border-radius: 0.25rem;
  /* color: white; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.input[type="file"] {
  margin: 20px 10px;
  border: 1px solid #03346E;
}
@media (max-width: 900px) {
  .input {
    width: 85%;
  }
}

.input:focus {
  border: 2px solid #03346E;
}
