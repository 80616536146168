.tobBar {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    padding: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}


.tobBarDark {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    padding: 10px;
    border-bottom: 2px solid #2163e8;
}
