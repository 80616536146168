.adTitle {
    margin: 20px 0px;
}

.adTitle h1 {
    color: #3D4B97;
    font-size: 2.5rem !important;
    margin: 40px 0px;
}

.adTitle .img {
    margin: 40px 0px;
}

.disType {
    margin: 40px 0px;
}

.disType h3 {
    margin-bottom: 10px;
    font-size: 1.8rem !important;
}

.disType h5 {
    color: #3D4B97;
    font-size: 1.5rem !important;
}

.latest-posts {
    margin: 50px 0;
}

.imgSpecificAds img {
    width: 300px;
    height: 300px;
}