.member {
    min-width: 200px;
    max-width: 90% !important;
    box-sizing: border-box; 
    background-color: rgba(33, 99, 232, 0.2);
    padding: 20px;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.personImg {
    text-align: center;
}

.personImg img {
    width:120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: auto !important;
    margin-right: auto !important;
    object-fit: cover;
    object-position: top;
}

.persDetails h5 {
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: center;
}

.persDetails h6 {
    color:#a1a1a8;
    text-align: center;
}
