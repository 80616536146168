footer {
    padding: 50px 10px;
    color: white;
    background-color: #000; /* Ensures the background blends well */
}

.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex-wrap: nowrap;
}

@media (max-width: 900px) {
    .footer-row {
        flex-direction: column;
        align-items: center;
    }
}

.summary, .links, .contact {
    flex: 1; /* Allows sections to occupy equal space */
    text-align: center;
    padding: 10px;
    min-width: 200px; /* Prevents sections from shrinking too much */
}

.summary-paragraph {
    color: rgba(255, 255, 255, 0.719);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5; /* Improves readability */
}

.links h4, .contact h4, .summary h4 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: white;
}

.pagesLinks {
    display: block;
    margin: 10px 0;
    color: rgba(255, 255, 255, 0.719);
    text-decoration: none;
}

.pagesLinks:hover {
    color: #2163e8;
    text-decoration: underline;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.719);
}

.phone a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.719);
}

.phone a:hover {
    text-decoration: underline solid rgba(255, 255, 255, 0.719) 2px;
}
