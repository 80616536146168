.selectt {
    width: 70%;
    height: 50px;
    border: 1px solid #03346E;
    outline: none;
    margin: 20px 10px !important;
    padding: 15px;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
.selectt option {
    color: black !important;
}
.searchButton {
    height: 50px;
    outline: none;
    border: none;
    padding: 15px;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    background-color: rgba(33, 99, 232, 0.2);
    font-size: 1.2rem;
}

.searchButton:hover {
    background-color: rgba(33, 99, 232, 0.527);
}