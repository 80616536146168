.slider {
    width: 100%;
    padding: 0 0 50px;
    border-radius: 30px !important;
}

.carousel {
  border-radius: 30px !important;
}

.slider img {
    height: 500px;
    object-fit:fill;
}


.caption {
    margin:170px auto;
    box-sizing: border-box !important;
    background-color: rgba(0, 0, 0, 0.658);
    border-radius: 30px;
    padding: 50px 10px;
}


/* slider.css */

/* Style for previous button */
.carousel-control-prev {
    width: 5%; /* Adjust width if needed */
  }
  
  .carousel-control-prev-icon {
    background-color: #2163e8; /* Background color for the icon */
    border-radius: 50%; /* Make it circular */
    padding: 20px; /* Add padding around the icon */
    width: 20px; /* Width of the icon */
    height: 20px; /* Height of the icon */
  }
  
  /* Style for next button */
  .carousel-control-next {
    width: 5%; /* Adjust width if needed */
  }
  
  .carousel-control-next-icon {
    background-color: #2163e8; /* Background color for the icon */
    border-radius: 50%; /* Make it circular */
    padding: 20px; /* Add padding around the icon */
    width: 20px; /* Width of the icon */
    height: 20px; /* Height of the icon */
  }
  
  /* Additional styling for hover effect */
  .carousel-control-prev-icon:hover,
  .carousel-control-next-icon:hover {
    background-color: #2163e8d5; /* Darker background on hover */
  }
  

  