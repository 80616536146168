.ad-img img{
    width: 100%;
    height: 350px;
    object-fit: fill;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ads-title {
    font-size: 2rem !important;
}
