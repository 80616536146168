.newsContent {
    width:100%;
    padding: 15px;
    margin: 30px auto;
    background-color: #202227;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
}

.right { 
    display: flex;
    gap:15px;
    align-items: center;
    flex-wrap: wrap;
}

.trendingTitle {
    background-color: #2163e8;
    padding: 10px;
    border-radius: 10px;
}

.trending h5 {
    font-size: 1rem !important;
}

.newsText a{
    font-weight: 300 !important;
    color: #a1a1a8;
    cursor: pointer;
    padding: 10px;
}

.transform span {
    cursor: pointer;
    color: #a1a1a8;
    padding: 10px 5px;
}

.transform span:hover {
    color: white;
}
