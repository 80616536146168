.adsPageTitle {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.377), rgba(0, 0, 0, 0.418)),
    url("https://img.freepik.com/free-photo/free-convenient-accessibility-movement-people-with-limited-mobility-wheelchair-stands_166373-5896.jpg?t=st=1725087293~exp=1725090893~hmac=4cd4ec729a6690b234bc97a2935e06f9f3d1ef94781b48fb06d14645102ab943&w=740");
  background-attachment:scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  margin-bottom: 50px;
}

.adsPageTitle h1 {
    color: white;
    font-size: 2.5rem !important;
    text-align: center;
    padding: 0 20px;
}


.adsCard {
    /* background-color: white; */
    padding: 20px 10px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    max-width: 250px !important;
    border: #03346E 1px solid;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
}

.adsCard:hover {
    cursor: pointer;
    transform: scale(1.1);
    border: #03346E 1px solid;
    
}

.adsCard .cardTop {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
    align-items: center;
}

.cardTop span{
    display: inline-block;
    background-color: #03346E;
    color: #a1a1a8;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;
}

.adsCard .adsImg img{
    width: 90%;
    height: 200px;
    margin: 15px auto;
}

.adsCard .adsDate {
    display: flex;
    gap: 10px;
    justify-content: center;
    /* align-items: center; */
}

.adsCard .adsDate h6{
    color:#a1a1a8;
    font-size: 1rem !important; 
}

.adsName h3{
    color:#3D4B97;
    font-size: 1.3rem !important;
}

.adsName h3:hover {
    text-decoration:underline solid #3D4B97 2px;
}