.newsCard{
    margin: 20px auto;
    width: 100%;
    height: 400px;
    
}

.newsImg img{
    min-width: 300px;
    max-width: 100%;
    height: 200px;
    object-fit: fill;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width:500px) {
    .newsImg img {
        width: 300px;
    }

    .newsCard {
        max-width: 300px;
    }
}

.newsDetails {
    margin:20px 10px;
}

.newsDetails h2 {
    font-size: 1.6rem !important;
    margin-bottom: 20px;
}

.newsDetails h2:hover {
    text-decoration: underline solid #2163e8 2px;
    cursor: pointer;
}


.newsDetails p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    color:#a1a1a8;
    height: 100px;
    overflow: auto;
    text-align: justify;
}